@import "variables";

.hero {
  width: 100%;
  padding: $padding-base * 4 $padding-base $padding-base * 2;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(180deg, #ecfaff 0%, #d0edff 100%);
  // TODO (CN) - Swap background-image back when "Give a bundle" project is done (~Aug/Sept 2020)
  // background-image: url("https://assets.decovostatic.com/static-images/pantry-shop-icons/pantryshop-header-background.jpg");
  background-size: cover;
  background-position: 50%;

  @include tablet {
    padding: $padding-base * 4;
  }
  @include laptop {
    padding: $padding-base * 2;
    flex-direction: row;
  }
}

.heroAction {
  max-width: 480px;
  text-align: center;

  @include laptop {
    text-align: left;
  }

  p {
    color: $primary-color;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.heroImageWrapper {
  margin: 0;
  margin-top: $margin-base;
  width: 400px;
  height: 400px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  position: relative;

  @include laptop {
    margin-left: $margin-base * 6;
    margin-top: 0;
  }

  @include mobile {
    width: 340px;
    height: 340px;
  }
}

.modulesTitle {
  margin-top: 30px;

  @media (min-width: $tablet-min) {
    line-height: 1.5;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

.modulesWrapper {
  padding-bottom: 40px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;

  div:focus {
    outline: none;

    button {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
}
