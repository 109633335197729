@import "variables";

.moduleBundle {
  margin: 20px 15px;
  cursor: pointer;

  width: 300px;

  @media (min-width: 360px) {
    width: 340px;
  }

  @include not-mobile {
    margin: 30px 25px;
  }

  button {
    margin-top: 20px;
  }
}

.title {
  font-size: 24px;
  text-align: center;
  color: $primary-color;
  margin: 0;
  margin-bottom: 20px;
}

.imageContainer {
  height: 226px;
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.image {
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
}
